import React, { FunctionComponent } from 'react'
import Section from '../components/Section'
import Hero from '../components/Hero'
import SroMainImage from '../assets/images/sro-main.png'
import SetupImage from '../assets/images/services/setup.png'
import LiquidationImage from '../assets/images/services/liquidation.png'
import ConversionImage from '../assets/images/services/conversion.png'
import ForeignImage from '../assets/images/services/foreign.png'
import TrademarksImage from '../assets/images/services/trademarks.png'
import OtherImage from '../assets/images/services/other.png'
import CivilImage from '../assets/images/services/civil.png'
import ChangesImage from '../assets/images/services/changes.png'
import CrayonImage from '../assets/images/services/crayon.png'
import SroChangesImage from '../assets/images/services/sro-changes.png'
import FreelanceStopImage from '../assets/images/services/freelance-stop.png'
import FreelanceRenewImage from '../assets/images/services/freelance-renew.png'
import Button from '../components/Button/Button'
import LocalizedLink from '../components/LocalizedLink'
import { graphql, useStaticQuery } from 'gatsby'
import useTranslations from '../components/useTranslations'
import { useLocale } from '../hooks/locale'

const heroImages = {
    default: SroMainImage,
    setup: SetupImage,
    liquidation: LiquidationImage,
    conversion: ConversionImage,
    foreign: ForeignImage,
    other: OtherImage,
    changes: ChangesImage,
    trademarks: TrademarksImage,
    civil: CivilImage,
    crayon: CrayonImage,
    sroChanges: SroChangesImage,
    freelanceStop: FreelanceStopImage,
    freelanceRenew: FreelanceRenewImage
}

const getHeroImageByKey = (key?: string) => {
    if (!key || !heroImages[key]) {
        return heroImages.default;
    }

    return heroImages[key];
}

type DetailPageProps = {}

const DetailPage: FunctionComponent<DetailPageProps> = ({ pageContext }) => {
    const { actions } = useTranslations()
    const { title, description, descriptionImage } = pageContext
    const heroImage = getHeroImageByKey(descriptionImage)

    return (
        <>
            <Section className="py-8" fullWidth>
                <div>
                    <Hero
                        headline={title}
                        subheadline={
                            description ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempus est et varius finibus. Nulla eget rutrum libero, ut pharetra odio. Pellentesque ac ligula porttitor risus vestibulum imperdiet."
                        }
                        heroImage={heroImage}
                        buttons={
                            <div>
                                <LocalizedLink to={'/order-form'}>
                                    <Button chevronVariant={'white'}>{actions.start}</Button>
                                </LocalizedLink>
                                <LocalizedLink to={'/#offer'}>
                                    <Button
                                        className={'ml-6 text-ink-60'}
                                        variant={'transparent'} chevronVariant={'ink_60'}
                                    >
                                        {actions.learn}
                                    </Button>
                                </LocalizedLink>
                            </div>
                        }
                        outerContainer
                    />
                </div>
            </Section>
        </>
    )
}

export default DetailPage